import { ORDER_STATUS, type OrderStatus } from '@/constants/global'
import {
  type AviatorOrderV2YamlResponsePaymentMethods,
  type AviatorOrderV2YamlResponse,
  type AviatorOrderV2YamlResponseOrderLines,
  type OrderHistoric,
  type AviatorOrderV2YamlResponseOrderImages,
} from '@/graphql-types'
import {
  type NormalizedOrderHistoryResponse,
  type Order,
  type OrderDisplayValue,
  type OrderLine,
  type Tag,
  type TagDisplayText,
  type TagId,
  type OrderSummary,
} from '@/services/Orders/types'
import { type Product, type ProductAPI } from '@/services/Product/types'
import { normalizeProduct } from '@/utils/normalizeProduct'
import isEmpty from 'lodash/isEmpty'
import { type OrderHistoryQuery } from '@/services/Orders/graphql/orderHistory.generated'
import { SPECIAL_REQUEST_PRODUCT_COST } from '@/components/Cart/constants'

export const getNormalizedGraphqlOrderLines = (
  order_lines: Maybe<Maybe<AviatorOrderV2YamlResponseOrderLines>[]> | undefined
): OrderLine<Product>[] => {
  if (!order_lines) return []
  // The products in the order.order_lines response here are not exactly the same
  // as products in other parts of our app (cart, for example) because
  // we add an additional "quantity" field to order products to track quantity changes
  return order_lines.map((line) => {
    const feedback = line?.customer_order_line_feedback?.reason
      ? {
          order_line_id: line?.customer_order_line_feedback?.order_line_id ?? 0,
          reason: line?.customer_order_line_feedback?.reason ?? '',
        }
      : undefined

    const product_price = line?.actual_price

    return line?.actual_product
      ? {
          id: line?.id ?? 0,
          notes: line?.notes ?? '',
          requested_qty: line?.requested_qty ?? 0,
          requested_product: normalizeProduct({
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- normalizing a product is not neccessary at this point
            ...(line?.requested_product as ProductAPI),
            quantity: line?.requested_qty ?? 0,
          }),
          requested_product_type: line?.requested_product_type ?? '',
          requested_product_id: line?.requested_product_id ?? 0,
          actual_product: normalizeProduct({
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- normalizing a product is not neccessary at this point
            ...(line?.actual_product as ProductAPI),
            price: product_price,
            quantity: (line?.actual_qty || line?.requested_qty) ?? 0,
          }),
          actual_qty: (line?.actual_qty || line?.requested_qty) ?? 0,
          not_found: line?.not_found ?? false,
          actual_price:
            (line?.actual_price || line?.requested_product?.price) ?? 0,
          actual_total:
            (line?.actual_total || line?.requested_product?.price) ?? 0,
          actual_product_type:
            (line?.actual_product_type || line?.requested_product_type) ?? '',
          customer_order_line_feedback: feedback,
        }
      : {
          id: line?.id ?? 0,
          notes: line?.notes ?? '',
          requested_qty: line?.requested_qty ?? 0,
          requested_product: normalizeProduct({
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- normalizing a product is not neccessary at this point
            ...(line?.requested_product as ProductAPI),
            quantity: line?.requested_qty ?? 0,
            ...(line?.requested_product_type === 'CustomProduct' && {
              product_id: line?.requested_product?.id,
              name: line?.requested_product?.description,
              product_type: line?.requested_product_type,
              is_custom_product: true,
              price: product_price ?? SPECIAL_REQUEST_PRODUCT_COST,
            }),
          }),
          requested_product_type: line?.requested_product_type ?? '',
          requested_product_id: line?.requested_product_id ?? 0,

          not_found: line?.not_found ?? false,
          actual_qty:
            line?.actual_qty !== undefined &&
            line.actual_qty !== null &&
            line.actual_qty >= 0
              ? Number(line.actual_qty)
              : undefined,

          customer_order_line_feedback: feedback,
        }
  })
}

export const normalizeGraphqlOrder = (
  order: AviatorOrderV2YamlResponse | undefined | null
): Order => {
  let orderDisplayValue: OrderDisplayValue[] = []
  if (order?.display_values) {
    for (let i = 0; i < order.display_values.length; i++) {
      orderDisplayValue = [
        ...orderDisplayValue,
        {
          key: order.display_values[i]?.key ?? '',
          value: order.display_values[i]?.value ?? '',
          display_name: order.display_values[i]?.display_name ?? '',
          display_values:
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- the type of OrderDisplayValue references itself and thus is difficult to provide a fallback
            order.display_values[i]?.display_values as OrderDisplayValue[],
          source: order.display_values[i]?.source ?? '',
        },
      ]
    }
  }
  let tags: Tag[] = []

  if (order?.shopper_rating?.tags) {
    for (let i = 0; i < order.shopper_rating.tags.length; i++) {
      tags = [
        ...tags,
        {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- TagId is an enum
          id: order.shopper_rating.tags[i]?.id as TagId,
          type: order.shopper_rating.tags[i]?.type ?? '',
          display_text:
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- TagDisplayText is an enum
            order.shopper_rating.tags[i]?.display_text as TagDisplayText,
        },
      ]
    }
  }

  const hasPaymentMethods = (
    maybeOrder: unknown
  ): maybeOrder is {
    payment_methods: AviatorOrderV2YamlResponsePaymentMethods[]
  } =>
    !!maybeOrder &&
    typeof maybeOrder === 'object' &&
    'payment_methods' in maybeOrder &&
    Array.isArray(maybeOrder.payment_methods)

  const getPaymentMethods = (): AviatorOrderV2YamlResponsePaymentMethods[] =>
    hasPaymentMethods(order) ? order.payment_methods : []

  const preferredShopper =
    order?.driver?.preferred_shopper?.favorite?.state === 'accepted' ||
    order?.driver?.preferred_shopper?.favorite?.state === 'requested'

  const getOrderImages = (): AviatorOrderV2YamlResponseOrderImages[] =>
    order?.status === ORDER_STATUS.DELIVERED
      ? (order?.order_images ?? []).filter((image) => !!image)
      : []

  return {
    id: order?.id ?? 0,
    order_lines: getNormalizedGraphqlOrderLines(order?.order_lines),
    rating: {
      id: order?.rating?.id ?? 0,
      rating: order?.rating?.rating ?? 0,
    },
    shopper_rating: {
      comments: order?.shopper_rating?.comments ?? '',
      rateable: order?.shopper_rating?.rateable ?? true,
      rating: order?.shopper_rating?.rating ?? 0,
      tags,
    },
    shipt_rating: order?.shipt_rating ?? 0,
    customer_address: {
      id: order?.customer_address?.id ?? 0,
      street1: order?.customer_address?.street1 ?? '',
      street2: order?.customer_address?.street2 ?? '',
      state: order?.customer_address?.state ?? '',
      zip_code: order?.customer_address?.zip_code ?? '',
      city: order?.customer_address?.city ?? '',
      delivery_instructions:
        order?.customer_address?.delivery_instructions ?? '',
      pickup_store_location_id:
        order?.customer_address?.pickup_store_location_id ?? 0,
      timezone: order?.customer_address?.timezone ?? '',
    },
    customer_address_id: order?.customer_address_id ?? 0,
    custom_content: {
      store_location: {
        id: order?.customer_address?.id ?? 0,
        street1: order?.customer_address?.street1 ?? '',
        street2: order?.customer_address?.street2 ?? '',
        state: order?.customer_address?.state ?? '',
        zip_code: order?.customer_address?.zip_code ?? '',
        city: order?.customer_address?.city ?? '',
        pickup_store_location_id:
          order?.customer_address?.pickup_store_location_id ?? 0,
        timezone: order?.customer_address?.timezone ?? '',
      },
    },
    payment_methods: getPaymentMethods(),
    driver: {
      id: order?.driver?.id ?? 0,
      name: order?.driver?.name ?? '',
      image_url: order?.driver?.image_url ?? '',
      can_request_favorite:
        order?.driver?.preferred_shopper?.can_request_favorite,
      preferred_shopper: isEmpty(order?.driver?.preferred_shopper)
        ? undefined
        : preferredShopper,
      preferred_pairing_id: order?.driver?.preferred_shopper?.favorite?.id ?? 0,
    },
    editable: order?.editable ?? false,
    loyalty_program: {
      name: order?.loyalty_program?.name ?? '',
      callout_text: order?.loyalty_program?.callout_text ?? '',
      loyalty_identifier: order?.loyalty_program?.loyalty_identifier ?? '',
    },
    order_images: getOrderImages(),
    report_problem_able: order?.report_problem_able ?? false,
    reschedulable: order?.reschedulable ?? false,
    substitution_preference: order?.substitution_preference ?? '',
    notes: order?.notes ?? '',
    status:
      // Orders are initially created with a status of 'created' to ensure they
      // are not bundled or offered to shoppers prior fraud checks. Once fraud
      // checks have completed, the order status is flipped to 'open'. This
      // usually only takes a few seconds.
      order?.status === ORDER_STATUS.CREATED
        ? ORDER_STATUS.OPEN
        : // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- OrderStatus is an enum
          (order?.status as OrderStatus),
    store: {
      store_id: order?.store?.store_id ?? 0,
      store_location_id: order?.store?.store_location_id ?? 0,
      metro_id: order?.store?.metro_id ?? 0,
      name: order?.store?.name ?? '',
      circular_image_url: order?.store?.circular_image_url ?? '',
      pickup_store_address_details:
        order?.store?.pickup_store_address_details ?? undefined,
    },
    time_window: order?.time_window ?? '',
    time_start: order?.time_start ?? '',
    time_end: order?.time_end ?? '',
    tippable: order?.tippable ?? false,
    delivered_at: order?.delivered_at ?? '',
    cancelled_at: order?.cancelled_at ?? '',
    display_values: orderDisplayValue,
    door_dropoff: order?.door_dropoff ?? false,
    early_delivery: order?.early_delivery ?? false,
    shop_only: order?.shop_only ?? false,
    pretip: order?.pretip ?? false,
    has_tipped: order?.has_tipped ?? false,
    source: order?.source ?? '',
    contains_alcohol: order?.contains_alcohol ?? false,
    contains_dex: order?.contains_dex ?? false,
    ebt_additional_amount_eligible: order?.ebt_additional_amount_eligible ?? 0,
    rescheduled: order?.rescheduled ?? false,
    reschedulable_by_end_time: order?.reschedulable_by_end_time ?? '',
    promotions:
      order?.promotions?.map((promotion) => ({
        promotion_key: promotion?.promotion_key,
        credit: promotion?.credit,
        context: promotion?.context,
        display_description: promotion?.display_description,
        display_name: promotion?.display_name,
        full_terms_title: promotion?.full_terms_title,
        full_terms_url: promotion?.full_terms_url,
        thresholds: promotion?.thresholds,
        type: promotion?.type,
        valid_through: promotion?.valid_through,
        valid_through_string: promotion?.valid_through_string,
        image_url: promotion?.image_url,
        id: promotion?.id,
      })) ?? [],
  }
}

const normalizeGraphqlOrderHistoric = (order: OrderHistoric): OrderSummary => {
  return {
    circular_image_url: order.circular_image_url,
    created_at: order.created_at,
    delivered_at: order.delivered_at,
    driver_id: order.driver_id,
    early_delivery: order.early_delivery,
    editable: order.editable,
    id: order.id,
    image_urls: Array.isArray(order.image_urls) ? order.image_urls : [],
    item_count: order.item_count,
    notes: order.notes,
    rating: order.rating ? { rating: order.rating.rating ?? 0 } : undefined,
    shop_only: order.shop_only ?? false,
    shipt_rating: order.shipt_rating ?? 0,
    shopper_image_url: order.shopper_image_url,
    shopper_rating: order.shopper_rating
      ? {
          comments: order?.shopper_rating?.comments ?? '',
          rateable: order?.shopper_rating?.rateable ?? true,
          rating: order?.shopper_rating?.rating ?? 0,
          tags:
            order.shopper_rating?.tags?.map((tag) => ({
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- TagId is an enum
              id: tag.id as TagId,
              type: tag.type ?? '',
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- TagDisplayText is an enum
              display_text: tag.display_text as TagDisplayText,
            })) ?? undefined,
        }
      : undefined,
    source: order.source ?? '',
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- OrderStatus is an enum
    status: order.status as OrderStatus,
    store_name: order.store_name,
    time_window: order.time_window,
    total: parseFloat(order.total),
  }
}

export const normalizeGraphqlOrderHistory = (
  data: OrderHistoryQuery['orderHistory']
): NormalizedOrderHistoryResponse => {
  if (!data) {
    return {
      upcomingOrders: [],
      pastOrders: [],
    }
  }

  return {
    upcomingOrders: [...data.upcomingOrders.map(normalizeGraphqlOrderHistoric)],
    pastOrders: [...data.pastOrders.map(normalizeGraphqlOrderHistoric)],
  }
}
