import { isObject } from '@/utils/isObject'
import { type GraphQlOrderError } from '@/services/Orders/types'

export const isGraphQlOrderError = (
  error: unknown
): error is GraphQlOrderError =>
  isObject(error) &&
  isObject(error.response) &&
  'errors' in error.response &&
  Array.isArray(error.response.errors)
