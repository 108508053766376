import { ORDER_STATUS } from '@/constants/global'
import {
  PickupIcon,
  CartIcon,
  PositiveCircleIcon,
  GroceryBasket2Icon,
  HomeIcon,
  OrderIcon,
  VehicleTruckLargeIcon,
} from '@shipt/design-system-icons'

export const ORDER_STATUS_TEXTS = {
  DELIVERED: 'Order delivered',
  PICKED_UP: 'Order picked up',
  ORDER_PLACED: 'Order placed',
  ORDER_CLAIMED: 'Order claimed',
  SHOP_IN_PROGRESS: 'Shopping in progress...',
  SHOP_COMPLETE: 'Shopping complete',
  ORDER_CANCELLED: 'Order cancelled',
  READY_FOR_PICKUP: 'Order ready for pickup',
}

const ORDER_STATUS_ICONS = {
  DELIVERED: HomeIcon,
  PICKED_UP: PickupIcon,
  ORDER_PLACED: PositiveCircleIcon,
  ORDER_CLAIMED: OrderIcon,
  SHOP_IN_PROGRESS: GroceryBasket2Icon,
  SHOP_COMPLETE: VehicleTruckLargeIcon,
  PREPARING_FOR_PICKUP: CartIcon,
  READY_FOR_PICKUP: PositiveCircleIcon,
}

const commonOrderStatuses = [
  {
    status: ORDER_STATUS.OPEN,
    text: ORDER_STATUS_TEXTS.ORDER_PLACED,
    icon: ORDER_STATUS_ICONS.ORDER_PLACED,
  },
  {
    status: ORDER_STATUS.CLAIMED,
    text: ORDER_STATUS_TEXTS.ORDER_CLAIMED,
    icon: ORDER_STATUS_ICONS.ORDER_CLAIMED,
  },
  {
    status: ORDER_STATUS.SHOPPING,
    text: 'Shopping',
    icon: ORDER_STATUS_ICONS.SHOP_IN_PROGRESS,
  },
]

export const deliveryOrderStatuses = [
  ...commonOrderStatuses,
  {
    status: ORDER_STATUS.PROCESSED,
    text: ORDER_STATUS_TEXTS.SHOP_COMPLETE,
    icon: ORDER_STATUS_ICONS.SHOP_COMPLETE,
  },
  {
    status: ORDER_STATUS.DELIVERED,
    text: 'Delivery',
    icon: ORDER_STATUS_ICONS.DELIVERED,
  },
]

export const pickupOrderStatuses = [
  ...commonOrderStatuses,
  {
    status: ORDER_STATUS.PROCESSED,
    text: ORDER_STATUS_TEXTS.SHOP_COMPLETE,
    icon: ORDER_STATUS_ICONS.PREPARING_FOR_PICKUP,
  },
  {
    status: ORDER_STATUS.READY_FOR_PICKUP,
    text: ORDER_STATUS_TEXTS.READY_FOR_PICKUP,
    icon: ORDER_STATUS_ICONS.READY_FOR_PICKUP,
  },
  {
    status: ORDER_STATUS.DELIVERED,
    text: 'Order pickup',
    icon: ORDER_STATUS_ICONS.PICKED_UP,
  },
]
